.pointContainer {
  position: absolute;
}

.pinpoint {
  width: 10px;
  height: 10px;
  padding: 5px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background: black;
  border: 1px solid white;
  border-radius: 30px;
  border-bottom-right-radius: 0;
  -webkit-animation: bounce 1.5s infinite;
  -moz-animation: bounce 1.5s infinite;
  animation: bounce 1.5s infinite;
  background-size: contain;
}

@-moz-keyframes bounce {
  0%,
  100% {
    margin: 30px 0 0 0;
  }
  50% {
    margin: 50px 0 0 0;
  }
}

@-webkit-keyframes bounce {
  0%,
  100% {
    margin: 30px 0 0 0;
  }
  50% {
    margin: 50px 0 0 0;
  }
}

@keyframes bounce {
  0%,
  100% {
    margin: 30px 0 0 0;
  }
  50% {
    margin: 50px 0 0 0;
  }
}
